import React from 'react';
import ReactDOM from 'react-dom/client';
import ExtractContract from './compoments/ExtractContract';  // Your Login component


import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router,Routes ,Navigate } from 'react-router-dom';  // Import HashRouter

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>  {/* Updated from <Switch> to <Routes> in React Router v6 */}
        <Route path="/" element={<Navigate to="/extractContract" />} />  {/* Use <Navigate> for redirect */}
        <Route path="/extractContract" element={<ExtractContract />} />  {/* Use <element> prop */}

      </Routes>
    </Router>
  </React.StrictMode>
);

// Measure app performance
reportWebVitals();

export default function authHeader(contentType = "application/x-www-form-urlencoded") {
  const userString = localStorage.getItem("user");

  let headers = {
    "Content-Type": contentType, // Default content type
  };

  if (userString) {
    try {
      const user = JSON.parse(userString);
      if (user && user.token) {
        headers["x-access-token"] = user.token; // Add token if it exists
      }
    } catch (error) {
      console.error("Error parsing user data from localStorage:", error);
    }
  }

  return headers;
}

import { useState } from "react";
import AIService from "../services/aiService"; // Adjust the path if needed
import ReactMarkdown from 'react-markdown';
import { jsPDF } from "jspdf";
import Modal from "react-modal";
import "../../src/UIX/assets/css/SignInForm.css";

// Set app element for accessibility (needed for react-modal)
Modal.setAppElement('#root');

const UploadTXT = () => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [contractText, setContractText] = useState("");
  const [contractPDF, setContractPDF] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const handleUpload = async () => {
    if (!file) return alert("Select a file first");

    try {
      const response = await AIService.uploadTXT(file, (progress) => {
        setUploadProgress(progress);
      });

      setContractText(response.fields || "");
      setContractPDF(response.pdfUrl); // Assume API returns a PDF URL
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Error uploading file");
    } finally {
      setUploadProgress(0);
    }
  };

  const handleDownloadPDF = () => {
    if (!contractText) return;

    const doc = new jsPDF();

    // Improved PDF styling (example)
    doc.setFont("times", "normal"); // Set font
    doc.setFontSize(12);
    const splitText = doc.splitTextToSize(contractText, 180); // Correct method for splitting text
    doc.text(splitText, 10, 10);

    doc.save("Generated_Contract.pdf");
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="upload-container">
      <div className="upload-card">
        <h2>Contract Generator</h2>
        <p>Upload a.txt file and generate a contract PDF</p>

        <div className="file-upload">
          <input
            type="file"
            accept=".txt"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>

        <button onClick={handleUpload} className="upload-button">
          Upload
        </button>

        {uploadProgress > 0 && (
          <div className="progress-bar">
            <div className="progress-bar-fill" style={{ width: `${uploadProgress}%` }}></div>
          </div>
        )}

        {contractText && (
          <div className="contract-preview">
            <h3>📜 Contract Preview</h3>
            <div
              contentEditable
              suppressContentEditableWarning
              onInput={(e) => setContractText(e.target.innerText)}
            >
              <ReactMarkdown>{contractText}</ReactMarkdown>
            </div>
          </div>
        )}

        {contractText && (
          <div className="download-buttons">
            <button onClick={openModal} className="edit-button">
              Edit Contract
            </button>
            <button onClick={handleDownloadPDF} className="generate-pdf-button">
              Generate PDF
            </button>
          </div>
        )}

        {contractPDF && (
          <div className="download-buttons">
            <a href={contractPDF} download="Generated_Contract.pdf" className="download-pdf-link">
              Download Contract PDF
            </a>
          </div>
        )}
      </div>

      {/* Modal for editing */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Contract"
        className="contract-modal" // Add a class for styling
        overlayClassName="contract-modal-overlay" // Add a class for the overlay
      >
        <h2>Edit Contract</h2>
        <div className="contract-editor">
          <textarea
            value={contractText}
            onChange={(e) => setContractText(e.target.value)}
            className="contract-textarea"
          />
        </div>
        <div className="modal-buttons">
          <button onClick={closeModal} className="modal-cancel-button">Cancel</button>
          <button onClick={() => { handleDownloadPDF(); closeModal(); }} className="modal-save-button">Save & Download</button>
        </div>
      </Modal>
    </div>
  );
};

export default UploadTXT;
import instance from "./http-common"; // Ensure you have a configured Axios instance
//const endpointStart ="http://127.0.0.1:5000/"
const endpointStart = "https://backendv2-1057981729709.us-central1.run.app/"
class AIService {
  async uploadPDF(pdfFile) {
    const formData = new FormData();
    formData.append("file", pdfFile);
    console.log(formData)
    const response = await instance.post("/upload_pdf", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }



  async uploadTXT(txtFile) { 
    const formData = new FormData();
    formData.append("file", txtFile); 
    const response = await instance.post("/upload_txt", formData);
    console.log(response)
    return response.data;
 }


  async sendFeedback(feedbackData) {
   const endpoint = endpointStart +  `send-feedback-outlook `

    try {
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(feedbackData),
        });

        if (!response.ok) {
            const message = `Error sending feedback: ${response.status}`;
            console.error(message);
            throw new Error(message);
        }
        console.log(response)
        return response; // Return the response data if successful
    } catch (error) {
        console.error("Error sending feedback:", error);
        throw error; // Re-throw the error to be handled by the caller
    }
  }
  
}

export default new AIService();
